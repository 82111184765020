import React from "react";
import Layout from "../components/layout";
import SEO from "../components/seo";

import Gallery from "../components/gallery.js";
import CategoryLink from "../components/catLink.js";


class AllProjects extends React.Component {
  render() {
    var design = this.props.design;
    var architecture = this.props.architecture;
    var products = this.props.products;

    var allProjects = design.concat(architecture).concat(products);

    var mapAcs = allProjects.sort((a, b) => {
      const delta =
        new Date(b.node.date).getTime() - new Date(a.node.date).getTime();
      return delta;
    });
    return (
      <main>
        {mapAcs.map((post, key) => (
          <section key={key}>
          <div className="mobile">
          {console.log(post.node.date)}
          <h1 className="mainTitle">{post.node.title}</h1>
          {post.node.acf.category_main_link_name ? (
            <CategoryLink link={post.node.acf.category_main_link_slug} name={post.node.acf.category_main_link_name}></CategoryLink>
         ) : (
           ``
         )}
        </div>
            <Gallery images={post.node.acf.gallery} key={post.node.id}>
              <div className="content">
                <h1 className="mainTitle screen">{post.node.title}</h1>
                {post.node.acf.category_main_link_name ? (
                 <CategoryLink link={post.node.acf.category_main_link_slug} name={post.node.acf.category_main_link_name}></CategoryLink>
              ) : (
                ``
              )}
                <div
                  dangerouslySetInnerHTML={{ __html: post.node.acf.content }}
                />
              </div>
            </Gallery>
          </section>
        ))}
      </main>
    );
  }
}

const IndexPage = ({ data }) => (
  <Layout>
    <SEO title="Konzeptstudio – Plan + Werk" />
    <AllProjects
      architecture={data.allWordpressWpArchitecture.edges}
      design={data.allWordpressWpDesign.edges}
      products={data.allWordpressWpProducts.edges}
    ></AllProjects>
  </Layout>
);

export default IndexPage;

export const query = graphql`
  query getCats($catname: String = "startpage") {
    allWordpressWpDesign(
      filter: { categories: { elemMatch: { slug: { eq: $catname } } } }
    ) {
      edges {
        node {
          slug
          id
          title
          date
          acf {
            content
            category_main_link_name
            category_main_link_slug
            gallery {
              localFile {
                childImageSharp {
                  # Try editing the "maxWidth" value to generate resized images.
                  fluid(maxWidth: 1800, srcSetBreakpoints: [600, 1200, 1800]) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
          }
          categories {
            name
            id
            slug
          }
        }
      }
    }
    allWordpressWpArchitecture(
      filter: { categories: { elemMatch: { slug: { eq: $catname } } } }
    ) {
      edges {
        node {
          slug
          id
          title
          date
          acf {
            content
            category_main_link_name
            category_main_link_slug
            gallery {
              localFile {
                childImageSharp {
                  # Try editing the "maxWidth" value to generate resized images.
                  fluid(maxWidth: 1800, srcSetBreakpoints: [600, 1200, 1800]) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
          }
          categories {
            name
            id
            slug
          }
        }
      }
    }
    allWordpressWpProducts(
      filter: { categories: { elemMatch: { slug: { eq: $catname } } } }
    ) {
      edges {
        node {
          slug
          id
          title
          menu_order
          date
          acf {
            content
            category_main_link_name
            category_main_link_slug
            gallery {
              localFile {
                childImageSharp {
                  # Try editing the "maxWidth" value to generate resized images.
                  fluid(maxWidth: 1800, srcSetBreakpoints: [600, 1200, 1800]) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
          }
          categories {
            name
            id
            slug
          }
        }
      }
    }
  }
`;
